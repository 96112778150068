import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ToastMessage from "./shared/ToastMessage";
import Login from "../components/authentication/Login";
import Landingpage from "./LandingPage";
import CompanyRegistration from "./company-details/CompanyRegistration";
import PageNotFound from "./authentication/pageNotFound";

const WrapperComponent = () => {
  const [pageError, setPageError] = useState(false);
  let token = sessionStorage.getItem("token");
  return (
    <>
      <div className="WrapperComponent">
        <ToastMessage />
        <Routes>
            <Route
              path="/login"
              element={token? <Navigate to="/dashboard" /> :<Login/>}
            />
            <Route
              path="/company-registration"
              element={<CompanyRegistration/>}
            />
            {pageError && (
              <Route
                path="/pageNotFound"
                component={<PageNotFound/>}
              />
            )}
        </Routes>
         <Landingpage setPageError={setPageError} />
      </div>
    </>
  );
};
export default WrapperComponent;
