import { TablePagination, Card, CardActions } from "@mui/material";
import React, { useEffect, useState } from "react";
import Spinner from "../components/shared/spinner/Spinner";
import TableBody from "./TableBody";
import TableHead from "./TableHead";
import PropTypes from "prop-types";

const DataTableWrapper = ({ data, columns, count, updateData, loading, statusCode, searchStr = "" }) => {
  DataTableWrapper.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    updateData: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    statusCode: PropTypes.number.isRequired,
    searchStr: PropTypes.string,
  };
  const [sortedData, setSortedData] = useState(data);
  useEffect(() => {
    setSortedData(data);
  }, [data]);

  DataTableWrapper.defaultProps = {
    searchStr: "",
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (searchStr) {
      setPage(0);
      updateData(0, rowsPerPage, searchStr);
    } else {
      updateData(page, rowsPerPage, searchStr);
    }
  }, [page, rowsPerPage, searchStr]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSort = (key, direction) => {
    const sortedArray = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData(sortedArray);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Card padding={"0"}>
          <div>
            <div style={{ overflow: "scroll", width: "auto" }}>
              <table className="table rwd-table">
                <TableHead
                  columns={columns}
                  onSort={handleSort}
                />
                <TableBody
                  columns={columns}
                  tableData={sortedData}
                  statusCode={statusCode}
                />
              </table>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                }}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                backIconButtonProps={{
                  color: "secondary",
                }}
                nextIconButtonProps={{ color: "secondary" }}
                showFirstButton={true}
                showLastButton={true}
                labelRowsPerPage={<span>Rows:</span>}
              />
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
export default DataTableWrapper;
