import React from "react";
import AppConstant from "../../../constants/AppConstant";
export default function Copyright(props) {
  return (
    <>
      <p className="text-center text-dark">
        <div>A product of</div> 
        <div>©&nbsp;{AppConstant.copyRightText} <br/>Pvt. Ltd. {new Date().getFullYear()}</div>
      </p>
    </>
  );
}
