import { Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from "@mui/material";
import React from "react";
/**
 *
 * @param {React.ElementType} children required
 * @param {string} heading modal heading
 * @param {React.ElementType} footer modal footer controls
 * @param {Function} closeFn modal required toogle
 * @param {Boolean} open required
 *
 */

const popUpTitleStyle = {
  borderBottom: "1px solid #e1e1e1",
};

export default function RctModel(props) {
  const { children, heading, subHeading, footer, closeFn, open, maxWidth, onSubmit, closePopUp } = props;

  const theme = useTheme();

  // const fullScreen = useMediaQuery(theme.breakpoints.down(maxWidth));
  return (
    <Dialog
      // fullScreen={fullScreen}
      open={open}
      onClose={closeFn}
      aria-labelledby="responsive-dialog-title"
      // scroll="body"
    >
      <DialogTitle style={popUpTitleStyle}>
        {heading}
        {closePopUp}
        {subHeading}
      </DialogTitle>
      {/* <DialogTitle>{subHeading}</DialogTitle> */}

      <DialogContent>{children}</DialogContent>
      {footer && <DialogActions className="footerAction">{footer}</DialogActions>}
    </Dialog>
  );
}
