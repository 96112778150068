import { useFormik } from "formik";
import React, { useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import PropTypes from "prop-types";

const AddTenant = ({ openDialog, setOpenDialog, tenantData }) => {
  AddTenant.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    tenantData: PropTypes.object,
  };
  let validationSchema = yup.object().shape({
    username: yup.string().required("Please Enter User Name"),
    password: yup.string().required("Please Enter Password"),
    schema: yup.string().required("Please Enter Schema"),
    enabled: yup.string().required("Please Select Status"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      schema: "",
      enabled: null,
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  const handleSubmit = async (values) => {
    await AxiosInstance.post(`/tenants`, values);
  };

  useEffect(() => {
    if (tenantData) {
      formik.setValues(tenantData);
    }
  }, [tenantData]);

  return (
    <>
      <ModelSelfFooter
        open={openDialog}
        heading={<IntlMessages id="form.text.registration" />}
        closePopUp={
          <CgClose
            onClick={() => setOpenDialog(false)}
            className="popUpCloseIcon"
          />
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="xs"
        footer={<span className="DisplayNone">.</span>}>
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="mdForm addCtcForm"
          onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="username">User Name</Label>
                <Input
                  type="text"
                  id="username"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                />
                {renderErrorMessage("username")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="password">Password</Label>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {renderErrorMessage("password")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="schema">Schema</Label>
                <Input
                  type="text"
                  id="schema"
                  name="schema"
                  value={formik.values.schema}
                  onChange={formik.handleChange}
                />
                {renderErrorMessage("schema")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="enabled">Enabled</Label>
                <Input
                  type="select"
                  id="enabled"
                  name="enabled"
                  value={formik.values.enabled}
                  onChange={formik.handleChange}>
                  <option>---Select Status---</option>
                  <option value={true}>Enable</option>
                  <option value={false}>Disable</option>
                </Input>
                {renderErrorMessage("enabled")}
              </FormGroup>
            </Col>
          </Row>
          <Row className="FooterForm">
            <Button
              variant="contained"
              color="outline-secondary"
              onClick={() => setOpenDialog(false)}
              className="buttonSpacing">
              <IntlMessages id={`button.text.cancel`} />
            </Button>
            <Button
              style={{ backgroundImage: `${AppColor.BackgroundImage}` }}
              variant="contained"
              type="submit"
              className="buttonSpacing">
              <IntlMessages id={`button.text.save`} />
            </Button>
          </Row>
        </Form>
      </ModelSelfFooter>
    </>
  );
};
export default AddTenant;
